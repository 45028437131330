import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"

const MotherStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #262626;
  position: relative;
  background: linear-gradient(
    61.74deg,
    #ff7e80 -4.05%,
    #ffa853 52.44%,
    #ffed7d 91.92%
  );
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const Text = styled.div`
  font-size: 18px;
  line-height: 30px;
  font-family: "Museo";
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    display: none;
  }

  div:first-of-type {
    font-size: 32px;
    line-height: 1.2;
    font-family: "BebasNeueBold";
    font-weight: bold;
    display: flex;
    align-items: center;

    span {
      background: #d7edf6;
      color: #262626;
      font-size: 36px;
      line-height: 1;
      padding: 5px;
      border-radius: 15px 0px;
      margin: 0px 15px;
    }
  }

  div:last-child {
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    font-family: "Museo";
    font-weight: 500;

    strong {
      font-weight: 700;
    }
  }
`

const DesktopBox = styled(Link)`
  font-size: 16px;
  font-family: "Museo";
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #262626;
  cursor: pointer;

  @media (max-width: 767px) {
    display: none;
  }

  :hover {
    color: #262626;
  }
`

const MobileBox = styled(Link)`
  font-size: 16px;
  font-family: "Museo";
  font-weight: 700;
  display: none;
  color: #262626;
  cursor: pointer;

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
  }

  :hover {
    color: #262626;
  }
`

const MobileText = styled.div`
  font-size: 18px;
  line-height: 30px;
  font-family: "Museo";
  font-weight: 500;
  letter-spacing: 0.5px;
  display: none;
  color: #262626;
  cursor: pointer;

  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }

  div:first-of-type {
    font-size: 32px;
    line-height: 1.2;
    font-family: "BebasNeueBold";
    font-weight: bold;
    display: flex;
    align-items: center;

    span {
      background: #d7edf6;
      color: #262626;
      font-size: 36px;
      line-height: 1;
      padding: 5px;
      border-radius: 15px 0px;
      margin: 0px 15px;

      @media (max-width: 550px) {
        margin: 0px 10px;
        font-size: 30px;
      }

      @media (max-width: 350px) {
        font-size: 24px;
      }
    }

    @media (max-width: 550px) {
      font-size: 26px;
    }

    @media (max-width: 350px) {
      font-size: 22px;
    }
  }

  div:last-child {
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    font-family: "Museo";
    font-weight: 500;

    strong {
      font-weight: 700;
    }
  }

  :hover {
    color: #262626;
  }
`

const Button = styled(Link)`
  color: #262626;
  font-size: 16px;
  font-family: "Museo";
  font-weight: 700;
  margin-left: 20px;
  text-decoration: underline;
  line-height: 22px;
  display: inline-block;

  @media (max-width: 767px) {
    display: none;
  }

  :hover {
    color: #262626;
  }
`

const CloseButton = styled.div`
  background: none;
  color: #262626;
  font-size: 20px;
  padding: 0px;
  box-shadow: none;
  position: absolute;
  line-height: 10px;
  font-weight: normal;
  right: 5px;
  top: 5px;
  margin: 0px;
  cursor: pointer;

  :hover {
    color: #262626;
  }
`

const MaiStickyBanner = ({ intl }) => {
  const [StickyTrans, setStickyTrans] = useState({})
  const [LyneHomeStickyHide, setLyneHomeStickyHide] = useState(false)
  const [activeFlag, setactiveFlag] = useState(true)

  var currentPath = ""
  var ButtonHide = false
  var regex = /(shop|panier|cart|lyneup|lynefit|lynehome)/
  if (isBrowser) {
    currentPath = window.location.pathname
    ButtonHide = regex.test(currentPath)
  }

  const data = useStaticQuery(graphql`
    query MaiStickyQuery {
      allDirectusCommonTranslation {
        nodes {
          blue_sticky_banner
          blue_sticky_mobile_banner
          blue_sticky_button
          newsletter_sticky_banner
          product_sticky_text
          mobile_product_sticky_text
          language
        }
      }
    }
  `)

  function Translation() {
    if (data && data.allDirectusCommonTranslation.nodes.length > 0) {
      let node
      data.allDirectusCommonTranslation.nodes.forEach((item, index) => {
        if (item.language === intl.locale) {
          node = index
        }
      })
      if (node >= 0) {
        setStickyTrans(data.allDirectusCommonTranslation.nodes[node])
      }
    }
  }

  const hideBanner = () => {
    isBrowser && sessionStorage.setItem("MaiStickyBanner", true)
    setLyneHomeStickyHide(true)
  }
  const checkVisibilityBanner = () => {
    if (isBrowser) {
      sessionStorage.getItem("MaiStickyBanner") && setLyneHomeStickyHide(true)
    }
  }

  const DiscountPercentage = () => {
    let discountText = ""
    if (process.env.GATSBY_ACTIVE_ENV === "eur") {
      if (intl.locale === "fr") {
        discountText = "-40%"
      } else {
        discountText = "40% off"
      }
    } else {
      if (intl.locale === "fr") {
        discountText = "-40%"
      } else {
        discountText = "40% off"
      }
    }
    return discountText
  }

  useEffect(() => {
    Translation()
    checkVisibilityBanner()
  }, [intl.locale])
  return (
    Object.values(StickyTrans).length > 0 &&
    !LyneHomeStickyHide &&
    activeFlag && (
      <MotherStickyBannerWrapper
        lang={intl.locale}
        className="LyneHomeStickyBanner"
      >
        <Container>
          {!ButtonHide ? (
            <DesktopBox to="/shop/lyneup/">
              <Text
                lang={intl.locale}
                dangerouslySetInnerHTML={{
                  __html: StickyTrans.blue_sticky_banner.replace(
                    "discount_percentage",
                    DiscountPercentage()
                  ),
                }}
              />
            </DesktopBox>
          ) : (
            <Text
              lang={intl.locale}
              dangerouslySetInnerHTML={{
                __html: StickyTrans.product_sticky_text.replace(
                  "discount_percentage",
                  DiscountPercentage()
                ),
              }}
            />
          )}
          {!ButtonHide && (
            <Button to="/shop/lyneup/">{StickyTrans.blue_sticky_button}</Button>
          )}
          {!ButtonHide ? (
            <MobileBox to="/shop/lyneup/">
              <MobileText
                lang={intl.locale}
                dangerouslySetInnerHTML={{
                  __html: StickyTrans.blue_sticky_mobile_banner.replace(
                    "discount_percentage",
                    DiscountPercentage()
                  ),
                }}
              />
            </MobileBox>
          ) : (
            <MobileText
              lang={intl.locale}
              dangerouslySetInnerHTML={{
                __html: StickyTrans.mobile_product_sticky_text.replace(
                  "discount_percentage",
                  DiscountPercentage()
                ),
              }}
            />
          )}
          <CloseButton onClick={() => hideBanner()}>×</CloseButton>
        </Container>
      </MotherStickyBannerWrapper>
    )
  )
}

export default injectIntl(MaiStickyBanner)
