import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import { isBrowser } from "../../../context/ApolloContext"

const BlackFridayGbpStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  background: #131112;
  color: #fddeb0;
  position: relative;

  @media (max-width: 767px) {
    padding: 5px 20px;
  }

  @media (max-width: 550px) {
    padding: 5px 10px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const TextWrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Text = styled.div`
  font-size: 18px;
  line-height: 30px;
  font-family: "Museo";
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`

const BlackFridayLogo = styled.img`
  width: 100%;
  max-width: 150px;
  margin: 0px;
  object-fit: contain;
`

const FirstText = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  @media (max-width: 767px) {
    flex-basis: ${({ flexBasis }) => (flexBasis ? "100%" : "auto")};
    justify-content: center;
  }

  @media (max-width: 450px) {
    font-size: 22px;
  }

  @media (max-width: 360px) {
    font-size: 20px;
  }
`

const SecondText = styled.div`
  font-size: 14px;
  line-height: 1.1;
  display: flex;
  font-family: "Museo";
  font-weight: 500;
  align-items: center;
  margin-left: 10px;

  > span {
    font-size: 30px;
    font-family: "BebasNeueBold";
    font-weight: bold;
    margin-right: 8px;
    line-height: 1;

    @media (max-width: 450px) {
      font-size: 26px;
      margin-right: 5px;
    }

    @media (max-width: 360px) {
      font-size: 24px;
    }
  }

  div {
    text-align: left;

    span {
      display: block;
    }
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 850px) {
    margin-left: 15px;
  }

  @media (max-width: 767px) {
    margin-left: 0px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
  }
`

const ThirdText = styled.div`
  font-size: 14px;
  line-height: 1.1;
  display: flex;
  font-family: "Museo";
  font-weight: 500;
  align-items: center;
  margin-left: 20px;

  > span {
    font-size: 30px;
    font-family: "BebasNeueBold";
    font-weight: bold;
    margin-right: 8px;
    line-height: 1;

    @media (max-width: 450px) {
      font-size: 26px;
      margin-right: 5px;
    }

    @media (max-width: 360px) {
      font-size: 24px;
    }
  }

  div {
    text-align: left;

    span {
      display: block;
    }
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 850px) {
    margin-left: 15px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
    margin-left: 10px;
  }
`

const CloseButton = styled.a`
  background: none;
  color: #fddeb0;
  font-size: 20px;
  padding: 0px;
  box-shadow: none;
  position: absolute;
  line-height: 10px;
  font-weight: normal;
  right: 5px;
  top: 5px;
  margin: 0px;
  cursor: pointer;
  z-index: 1;

  :hover {
    color: #fddeb0;
  }
`

const BlackFridayGbpStickyBanner = ({ intl }) => {
  const [LyneHomeStickyHide, setLyneHomeStickyHide] = useState(false)
  const { pathname } = useLocation()
  var currentPath = ""

  let isShopPage = false

  let shopRegex = /(solutions-textiles|wearable-technology)/

  if (isBrowser) {
    currentPath = window.location.pathname

    isShopPage = shopRegex.test(currentPath)
  }

  const hideBanner = () => {
    isBrowser && sessionStorage.setItem("BlackFridayStickyBanner", true)
    setLyneHomeStickyHide(true)
  }
  const checkVisibilityBanner = () => {
    if (isBrowser) {
      sessionStorage.getItem("BlackFridayStickyBanner") &&
        setLyneHomeStickyHide(true)
    }
  }

  useEffect(() => {
    checkVisibilityBanner()
  }, [intl.locale])

  return (
    !LyneHomeStickyHide && (
      <BlackFridayGbpStickyBannerWrapper className="LyneHomeStickyBanner">
        <Container>
          <TextWrapper>
            <LinkBox to="/shop/lyneup-test/">
              <LinkButtonTitle>Je découvre</LinkButtonTitle>
            </LinkBox>
            <Text>
              <FirstText flexBasis="100%">
                <BlackFridayLogo
                  className="lazyload"
                  data-src="https://static.percko.com/uploads/ab63ef88-b4f4-4cad-ae38-e98ced70a2aa.gif"
                />
              </FirstText>
              <SecondText>
                <span>£15 off</span>
                <div>
                  <strong>one</strong> PERCKO <span>vest</span>
                </div>
              </SecondText>
              <ThirdText>
                <span>£50 off</span>
                <div>
                  <strong>two</strong> PERCKO <span>vests</span>
                </div>
              </ThirdText>
            </Text>
          </TextWrapper>
        </Container>
        <CloseButton onClick={hideBanner}>×</CloseButton>
      </BlackFridayGbpStickyBannerWrapper>
    )
  )
}

export default injectIntl(BlackFridayGbpStickyBanner)
