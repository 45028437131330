export const colors = {
  white: {
    base: "#fff",
    lightest: "#FAFAFA",
    light: "#f0f0f0",
    grey: "#cecece",
    dark: "#AAAAAA",
  },
  black: {
    base: "#262626",
    light: "#535353",
    dark: "#000",
    footer_top: "#212126",
    footer_bot: "#202023",
  },
  primary: {
    lyneup: "#5db9bc",
    lynefit: "#9edaed",
    lynepro: "#bc0000",
    lynehome: "#C0DBEE",
    wavii: "#FF8049",
    waviiSpan: "#e8645e",
    loop: "#213D5B",
    exclusif: "#ec008c",
    enplus: "#FF550A",
  },
  background: {
    light: "#3e5fbc",
    dark: "#284187",
  },
  text: {
    light: "#898888",
    promo: "#777",
    dark: "#000000",
  },
  button: {
    red: "#be3025",
    black: "#262626",
  },
  border: {
    blue: "#00c4c1",
    orange: "#ffab61",
  },
}

export const shadow = {
  card: "0 0 15px 2px rgba(206,206,206,.5)",
  button: "inset 0 1.2em 0 0 rgba(255,255,255,.1)",
}

export const saleColor = {
  // Sale Background Color
  bgColor: `#FEDB81`,
  // Tag Gradient Background Color
  tagGradient: `linear-gradient(61.74deg, #ff7e80 -4.05%, #ffa853 52.44%, #ffed7d 91.92%);`,
  tagBlueGradient: `linear-gradient(88.29deg, #27f596 1.11%, #3fe2ad 19.4%, #19c3de 69.83%);`,
  // Tag Normal Background Color
  bgTagColor: `#B2FFDF`,
  // Tag Font Color
  textColor: `#262626`,
  // Sale Border Color
  borderColor: `#CD2C2F`,
  // Sale Price Color
  priceColor: `#262626`,
  // Sale Menu Color
  menuColor: `#ff0000`,
}

export const gradient = {
  // eslint-disable-next-line
  leftToRight: `linear-gradient(145deg,rgba(255,78,0,1) 10%,rgba(220,0,110,1) 100%)`,
  // eslint-disable-next-line
  rightToLeft: `linear-gradient(145deg,rgba(220,0,110,1) 10%, rgba(255,78,0,1) 100%)`,
  // button-gradient1-background-color
  buttonGradient1: `linear-gradient(45deg,#a5fab7 0%,#00c4c1 55.94%,#50b5d5 100%);`,
  // button-gradient2-background-color
  buttonGradient2: `linear-gradient(61.74deg, #ff7881 -4.05%, #ffab61 52.44%, #ffe775 91.92%);`,
}

export const transition = {
  easeInOutCubic: "cubic-bezier(0.645, 0.045, 0.355, 1)",
  easeOutBack: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
  duration: "0.4s",
}

export const theme = {
  colors,
  saleColor,
  gradient,
  shadow,
  transition,
  breakpoints: {
    xs: "400px",
    s: "600px",
    m: "900px",
    l: "1200px",
    rs: "1080px",
  },
  fontFamily: {
    // eslint-disable-next-line
    body: `'GothamLight', Montserrat,-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    // eslint-disable-next-line
    heading: `'BebasNeueBold', 'Gotham', Montserrat,-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  },
}

export default theme
