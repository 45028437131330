import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import Header from "../Header"
import WelcomeBar from "../WelcomeBar"
import StickyMobileMenu from "../StickyMobileMenu"
import LyneHomeStickyBanner from "../LyneHomeStickyBanner"
import ExclusifStickyBanner from "../ExclusifStickyBanner"
import ExcluStickyBanner from "../ExcluStickyBanner"
import MaiStickyBanner from "../MaiStickyBanner"
import ChristmasStickyBanner from "../ChristmasStickyBanner"
import OfferStickyBanner from "../OfferStickyBanner"
import ChristmasGbpStickyBanner from "../ChristmasGbpStickyBanner"
import RentreeStickyBanner from "../RentreeStickyBanner"
import CartErrorBanner from "../CartErrorBanner"
import BlackFridayGbpStickyBanner from "../BlackFridayGbpStickyBanner"
import BlackFridayStickyBanner from "../BlackFridayStickyBanner"
import FlashStickyBanner from "../FlashStickyBanner"
import FlashGbpStickyBanner from "../FlashGbpStickyBanner"

const HeaderSection = ({
  intl,
  children,
  exclusifPromo,
  isIdealPage,
  isNotCartPage,
  isNotLyneupExclusifPage,
}) => {
  const [hideStickyMenu, sethideStickyMenu] = useState(false)

  const { pathname } = useLocation()

  const handleHeaderVisiblity = () => {
    $(document).ready(function() {
      var c,
        currentScrollTop = 0,
        scrollHeader = $("#scrollUPHeader"),
        navbar = $("#topHeader"),
        bodyBox = $("body")
      bodyBox.removeClass("headerSticky")
      scrollHeader.css({ transform: "unset" })

      window.addEventListener("scroll", function(e) {
        var a = $(window).scrollTop()
        var b = navbar.height()

        currentScrollTop = a

        if (c < currentScrollTop && a > b + b) {
          scrollHeader.css({ transform: "translateY(-" + b + "px)" })
          bodyBox.addClass("headerSticky")
        } else if (c > currentScrollTop && !(a <= b)) {
          scrollHeader.css({ transform: "unset" })
          bodyBox.removeClass("headerSticky")
        }
        c = currentScrollTop
      })
    })
  }

  const pageUrlHandler = () => {
    let currentPath = ""

    let hideStickyRegex = /(dispositif-medical|lyneup|notre-histoire|ils-nous-aiment|nos-revendeurs|shop|back-health|les-maux-de-dos)/

    currentPath = window.location.pathname

    if (
      pathname === "/" ||
      pathname === `/${intl.locale}/` ||
      pathname === `/${process.env.GATSBY_CURRENCY_TYPE}/` ||
      pathname === `/${process.env.GATSBY_CURRENCY_TYPE}/${intl.locale}/` ||
      hideStickyRegex.test(currentPath)
    )
      sethideStickyMenu(false)
    else sethideStickyMenu(true)
  }

  useEffect(() => {
    pageUrlHandler()
    isBrowser && handleHeaderVisiblity()
  }, [pathname])

  return (
    <HeaderSectionWrapper id="scrollUPHeader">
      <TopHeader id="topHeader">
        {children}
        {isIdealPage && <WelcomeBar exclusifPromo={exclusifPromo} />}
        {isIdealPage && <Header exclusifPromo={exclusifPromo} />}
      </TopHeader>
      <BottomHeader id="bottomHeader">
        {/* {(process.env.GATSBY_ACTIVE_ENV === "eur" ||
          process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          isIdealPage &&
          isNotCartPage &&
          isNotLyneupExclusifPage && <ChristmasStickyBanner />} */}
        {(process.env.GATSBY_ACTIVE_ENV === "eur" ||
          process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          isIdealPage &&
          isNotCartPage &&
          isNotLyneupExclusifPage && <OfferStickyBanner />}
        {/* {(process.env.GATSBY_ACTIVE_ENV === "eur" ||
          process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          isNotLyneupExclusifPage &&
          isIdealPage &&
          isNotCartPage && <FlashStickyBanner />} */}
        {/* {(process.env.GATSBY_ACTIVE_ENV === "eur" ||
          process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          isNotLyneupExclusifPage &&
          isIdealPage &&
          isNotCartPage && <RentreeStickyBanner />} */}
        {/* {(process.env.GATSBY_ACTIVE_ENV === "eur" ||
          process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          isNotLyneupExclusifPage &&
          isIdealPage &&
          isNotCartPage && <BlackFridayStickyBanner />} */}
        {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          isIdealPage &&
          isNotCartPage &&
          isNotLyneupExclusifPage && <ChristmasGbpStickyBanner />}
        {/* {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          isNotLyneupExclusifPage &&
          isIdealPage &&
          isNotCartPage && <FlashGbpStickyBanner />} */}
        {/* {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          isNotLyneupExclusifPage &&
          isIdealPage &&
          isNotCartPage && <BlackFridayGbpStickyBanner />} */}
        {(process.env.GATSBY_ACTIVE_ENV === "eur" ||
          process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          exclusifPromo === "exclusif" &&
          isIdealPage &&
          isNotCartPage &&
          !isNotLyneupExclusifPage && <ExclusifStickyBanner />}
        {(process.env.GATSBY_ACTIVE_ENV === "eur" ||
          process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          exclusifPromo === "exclu" &&
          isIdealPage &&
          isNotCartPage &&
          !isNotLyneupExclusifPage && <ExcluStickyBanner />}
        {(intl.locale === "de" || intl.locale === "es") &&
          isIdealPage &&
          isNotCartPage &&
          isNotLyneupExclusifPage && <LyneHomeStickyBanner />}
        {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          intl.locale === "en" &&
          isIdealPage &&
          isNotCartPage &&
          isNotLyneupExclusifPage && <MaiStickyBanner />}
        {process.env.GATSBY_ACTIVE_ENV === "cad" &&
          intl.locale === "fr" &&
          isIdealPage &&
          isNotCartPage &&
          isNotLyneupExclusifPage && <MaiStickyBanner />}
        {intl.locale === "en" &&
          isIdealPage &&
          isNotCartPage &&
          isNotLyneupExclusifPage &&
          hideStickyMenu && <StickyMobileMenu />}
        {/* <CartErrorBanner /> */}
      </BottomHeader>
    </HeaderSectionWrapper>
  )
}

const HeaderSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 5;
  transition: all 0.5s;
`

const TopHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  transition: all 0.5s;
`

const BottomHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

export default injectIntl(HeaderSection)
