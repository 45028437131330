import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"

const LyneHomeStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 10px 20px 7px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(90deg, #a5fab7, #00c4c1, #50b5d5);
  color: #262626;
  position: relative;

  @media (max-width: 550px) {
    padding: 5px 0px 2px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const Text = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: "GothamLight";
  letter-spacing: 0.5px;

  span {
    font-size: 25px;
    line-height: 28px;
    font-family: "Gotham Book";
    font-weight: bold;

    @media (max-width: 850px) {
      font-size: 24px;
    }
  }

  strong {
    font-family: "Gotham Book";
    font-size: 21px;
    line-height: 24px;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const MobileBox = styled(Link)`
  font-size: 16px;
  font-family: "GothamLight";
  display: none;
  color: #262626;
  cursor: pointer;

  @media (max-width: 767px) {
    display: inline;
  }

  :hover {
    color: #262626;
  }
`

const MobileText = styled.div`
  font-size: 16px;
  font-family: "GothamLight";
  letter-spacing: 0.5px;
  display: none;
  color: #262626;
  cursor: pointer;
  line-height: 1;

  @media (max-width: 767px) {
    display: inline;
  }

  @media (max-width: 550px) {
    font-size: 13px;
    line-height: 16px;
  }

  @media (max-width: 360px) {
    font-size: 12px;
  }

  span {
    font-family: "Gotham Book";
    font-weight: bold;
    font-size: 25px;
    line-height: 28px;

    @media (max-width: 850px) {
      font-size: 24px;
    }

    @media (max-width: 550px) {
      font-size: 18px;
      line-height: 20px;
    }

    @media (max-width: 480px) {
      font-size: 13px;
      line-height: 16px;
    }

    @media (max-width: 360px) {
      font-size: 12px;
    }
  }

  strong {
    font-size: 21px;
    line-height: 24px;
    font-family: "Gotham Book";
    font-weight: bold;

    @media (max-width: 550px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: 480px) {
      font-size: 13px;
      line-height: 16px;
    }

    @media (max-width: 360px) {
      font-size: 12px;
    }
  }

  :hover {
    color: #262626;
  }
`

const Button = styled(Link)`
  border-radius: 5px;
  color: #262626;
  font-size: 16px;
  font-family: "Gotham Book";
  margin-top: 5px;
  margin-left: 20px;
  line-height: 22px;
  display: inline-block;
  // box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.2);

  @media (max-width: 767px) {
    display: none;
  }

  :hover {
    color: #262626;
  }
`

const MobileButton = styled.div`
  font-size: 11px;
  font-weight: bold;
  font-family: "GothamLight";
  color: #262626;
  cursor: pointer;
  display: inline;
  margin: 0px 0px 0px 5px;

  :hover {
    color: #262626;
  }
`

const CloseButton = styled.a`
  background: none;
  color: #262626;
  font-size: 20px;
  padding: 0px;
  box-shadow: none;
  position: absolute;
  line-height: 10px;
  font-weight: normal;
  right: 5px;
  top: 5px;
  margin: 0px;
  cursor: pointer;

  :hover {
    color: #262626;
  }
`

export const LyneHomeStickyBanner = ({ intl }) => {
  const [StickyTrans, setStickyTrans] = useState({})
  const [LyneHomeStickyHide, setLyneHomeStickyHide] = useState(false)
  const [activeFlag, setactiveFlag] = useState(true)
  var currentPath = ""
  var ButtonHide = false
  var regex = /(shop|panier|cart|lyneup|lynefit|lynehome)/
  if (isBrowser) {
    currentPath = window.location.pathname
    ButtonHide = regex.test(currentPath)
  }
  const data = useStaticQuery(graphql`
    query LyneHomeStickyQuery {
      allDirectusCommonTranslation {
        nodes {
          blue_sticky_banner
          blue_sticky_mobile_banner
          blue_sticky_button
          language
        }
      }
    }
  `)
  function Translation() {
    if (data && data.allDirectusCommonTranslation.nodes.length > 0) {
      let node
      data.allDirectusCommonTranslation.nodes.forEach((item, index) => {
        if (item.language === intl.locale) {
          node = index
        }
      })
      if (node >= 0) {
        setStickyTrans(data.allDirectusCommonTranslation.nodes[node])
      }
    }
  }
  const hideBanner = () => {
    isBrowser && sessionStorage.setItem("LyneHomeStickyBanner", true)
    setLyneHomeStickyHide(true)
  }
  const checkVisibilityBanner = () => {
    if (isBrowser) {
      sessionStorage.getItem("LyneHomeStickyBanner") &&
        setLyneHomeStickyHide(true)
    }
  }

  useEffect(() => {
    Translation()
    checkVisibilityBanner()
  }, [intl.locale])
  return (
    Object.values(StickyTrans).length > 0 &&
    !LyneHomeStickyHide &&
    activeFlag && (
      <LyneHomeStickyBannerWrapper
        store={process.env.GATSBY_ACTIVE_ENV}
        className="LyneHomeStickyBanner"
      >
        <Container>
          <Text
            dangerouslySetInnerHTML={{
              __html: StickyTrans.blue_sticky_banner,
            }}
          />
          {!ButtonHide && (
            <Button store={process.env.GATSBY_ACTIVE_ENV} to="/shop/lyneup-2b/">
              {StickyTrans.blue_sticky_button}
            </Button>
          )}
          {!ButtonHide ? (
            <MobileBox
              store={process.env.GATSBY_ACTIVE_ENV}
              to="/shop/lyneup-2b/"
            >
              <MobileText
                store={process.env.GATSBY_ACTIVE_ENV}
                dangerouslySetInnerHTML={{
                  __html: StickyTrans.blue_sticky_mobile_banner,
                }}
              />
              <MobileButton store={process.env.GATSBY_ACTIVE_ENV}>
                {" >>"}
              </MobileButton>
            </MobileBox>
          ) : (
            <MobileText
              store={process.env.GATSBY_ACTIVE_ENV}
              dangerouslySetInnerHTML={{
                __html: StickyTrans.blue_sticky_mobile_banner,
              }}
            />
          )}
          <CloseButton
            store={process.env.GATSBY_ACTIVE_ENV}
            onClick={hideBanner}
          >
            ×
          </CloseButton>
        </Container>
      </LyneHomeStickyBannerWrapper>
    )
  )
}

export default injectIntl(LyneHomeStickyBanner)
