import "./src/styles/global.css"
import "lazysizes"

// export const disableCorePrefetching = () => true

export const onRouteUpdate = (_, pluginOptions) => {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
        window.dataLayer.push({ event: `optimize.activate`})
    }, 50)
}

let nextRoute = ``

export const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname
}

window.addEventListener("unhandledrejection", event => {
   if (/loading chunk \d* failed./i.test(event.reason)) {
        if (nextRoute) {
          window.location.pathname = nextRoute
       }
    }
});
